import React from 'react'
import PropTypes from 'prop-types'
import styled from '@material-ui/core/styles/styled'
import Button from '@material-ui/core/Button/Button'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'


const StyledButton = styled(Button)(({selected}) => ({
  'backgroundColor': selected && '#262E2C',
  'color': selected && 'white',
  '&:hover': {
    backgroundColor: selected ? '#262E2C' : 'inherit',
    color: selected ? 'white' : 'inherit',
  },
}))

const StyledWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '3rem',
})

const Filters = ({isMenuSelected, setMenuSelected, isDeliverySelected, setDeliverySelected, ...props}) => (
  <StyledWrapper {...props}>
    <StyledButton
        selected={isDeliverySelected}
        startIcon={<DirectionsCarIcon />}
        variant="outlined"
        onClick={() => setDeliverySelected((prevState) => !prevState)}
    >
      Donáška
    </StyledButton>
    <StyledButton
        selected={isMenuSelected}
        startIcon={<DateRangeIcon />}
        variant="outlined"
        onClick={() => setMenuSelected((prevState) => !prevState)}
    >
      Denné menu
    </StyledButton>
  </StyledWrapper>
)

Filters.propTypes = {
  isMenuSelected: PropTypes.bool.isRequired,
  setMenuSelected: PropTypes.func.isRequired,
  isDeliverySelected: PropTypes.bool.isRequired,
  setDeliverySelected: PropTypes.func.isRequired,
}


export default Filters

import React from 'react'
import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import Chip from '@material-ui/core/Chip'


const RestaurantTypes = ({types, isIndex, ...props}) => (
  <>
    <For each="type" of={types}>
      <Chip key={type} isIndex={isIndex} label={type} variant="outlined" {...props} />
    </For>
  </>
)

RestaurantTypes.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  isIndex: PropTypes.bool,
}

export default RestaurantTypes

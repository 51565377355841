import React from 'react'
import PropTypes from 'prop-types'
import {For} from 'babel-plugin-jsx-control-statements'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card/Card'
import Button from '@material-ui/core/Button/Button'
import CardHeader from '@material-ui/core/CardHeader/CardHeader'
import CardMedia from '@material-ui/core/CardMedia/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea'
import CardActions from '@material-ui/core/CardActions/CardActions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {CardContent} from '@material-ui/core'
import plusSign from '../../images/plus.png'
import Restaurant from './Restaurant'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    color: '#262E2C',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    fontWeight: '700',
  },
  subheader: {
    fontSize: '0.8rem',
  },
  media: {
    position: 'relative',
    paddingTop: '56.25%', // 16:9
    width: '100%',
    height: 0,
  },
  content: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  actions: {
    justifyContent: 'center',
    padding: '1rem',
  },
}))

const Restaurants = ({data, ...props}) => {
  const classes = useStyles()
  const [hasMounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)
  }, [])

  if (!hasMounted) return null


  return (
    <>
      <Grid container spacing={2} {...props}>
        <For each="restaurant" of={data}>
          <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
            <Restaurant data={restaurant} />
          </Grid>
        </For>
        <Grid item xs={12} sm={6} md={4}>
          <Card component="article" className={classes.root} key={data.id} {...props}>
            <CardHeader
                title={<span className={classes.header}>Nenašli ste sa?</span>}
                subheader={<span className={classes.subheader}>Tu môže byť bezplatne aj vaša reštaurácia</span>}
            />
            <CardActionArea href="mailto:brand@sited.sk" target="_blanl" rel="noreferrer noopener">
              <CardMedia className={classes.media} image={plusSign} title="Napíšte nám" />
            </CardActionArea>
            <CardContent className={classes.content}>
              brand@sited.sk
            </CardContent>
            <CardActions
                className={classes.actions}
                href="mailto:brand@sited.sk"
                target="_blanl"
                rel="noreferrer noopener"
            >
              <Button variant="outlined" href="mailto:brand@sited.sk" target="_blank" rel="noreferrer noopener">
                Napíšte nám
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

Restaurants.propTypes = {
  data: PropTypes.array.isRequired,
}

export default Restaurants

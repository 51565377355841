import React from 'react'
import PropTypes from 'prop-types'
import {If, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash'
import {graphql, navigate} from 'gatsby'
import slug from 'slug'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import CallModal from '../CallModal'
import RestaurantTypes from './RestaurantTypes'


export const restaurantFragment = graphql`
  fragment Restaurant on Frontmatter {
    title
    thumbnail
    priority
    mobileNumber
    address
    googleMaps
    menu
    special
    notes
    packagePrice
    deliveryPrice
    minimalPrice
    isDelivery
    isDailyMenu
    dailyMenuLink
    types
    openingHours {
      isSundayOpen
      isSaturdayOpen
      monday {
        from
        to
      }
      tuesday {
        from
        to
      }
      wednesday {
        from
        to
      }
      thursday {
        from
        to
      }
      friday {
        from
        to
      }
      saturday {
        from
        to
      }
      sunday {
        from
        to
      }
    }
    socials {
      facebookUrl
      webUrl
      instagramUrl
    }
    dailyMenu {
      isSundayOpen
      isSaturdayOpen
      monday {
        from
        to
        toSold
      }
      tuesday {
        from
        to
        toSold
      }
      wednesday {
        from
        to
        toSold
      }
      thursday {
        from
        to
        toSold
      }
      friday {
        from
        to
        toSold
      }
      saturday {
        from
        to
        toSold
      }
      sunday {
        from
        to
        toSold
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    filter: ({isOpen}) => isOpen ? 'none' : 'grayscale(1)',
    opacity: ({isOpen}) => isOpen ? 'none' : '0.6',
    width: '100%',
    height: '100%',
    color: '#262E2C',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    fontWeight: '700',
  },
  subheader: {
    fontSize: '0.8rem',
    color: '#262E2C',
  },
  actionArea: {
    flexFlow: 'column',
  },
  media: {
    position: 'relative',
    paddingTop: '56.25%', // 16:9
    width: '100%',
    height: 0,
  },
  content: {
    padding: '1rem',
  },
  callButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: '1.8rem',
    color: '#262E2C',
    border: '1px solid #262E2C',
    borderRadius: '50%',
  },
  actions: {
    justifyContent: 'center',
    padding: '1rem',
  },
  badgeWrapper: {
    position: 'absolute',
    bottom: 0,
    padding: '0.5rem 1rem',
    color: '#262E2C',
  },
  badge: {
    padding: '0.25rem',
    marginRight: '0.5rem',
    backgroundColor: 'white',
    fontSize: '2rem',
    borderRadius: '50%',
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.5)',
  },
}))

const Restaurant = ({data, ...props}) => {
  const classes = useStyles({isOpen: data.isOpen})

  return (
    <Card component="article" className={classes.root} key={data.id} {...props}>
      <CardHeader
          title={
            <span className={classes.header}>
              {data.title}
            </span>
          }
          subheader={
            <Choose>
              <When condition={!isEmpty(data.googleMaps)}>
                <a href={data.googleMaps} target="_blank" rel="noreferrer" className={classes.subheader}>
                  {data.address}
                </a>
              </When>
              <Otherwise>
                <span className={classes.subheader}>
                  {data.address}
                </span>
              </Otherwise>
            </Choose>
          }
          action={
            <If condition={!isEmpty(data.mobileNumber)}>
              <CallModal title={data.title} mobileNumber={data.mobileNumber} />
            </If>
          }
      />
      <CardActionArea
          className={classes.actionArea}
          component="a"
          onClick={() => navigate(`/${slug(data.title)}/`, {state: {modal: true}})}
      >
        <CardMedia className={classes.media} image={data.thumbnail} title={data.title}>
          <div className={classes.badgeWrapper}>
            <If condition={data.isDelivery}>
              <DirectionsCarIcon className={classes.badge} />
            </If>
            <If condition={data.isDailyMenu}>
              <DateRangeIcon className={classes.badge} />
            </If>
          </div>
        </CardMedia>
        <CardContent className={classes.content}>
          <RestaurantTypes types={data.types} isIndex />
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Button variant="outlined" onClick={() => navigate(`/${slug(data.title)}/`, {state: {modal: true}})}>
          Zobraziť ponuku
        </Button>
      </CardActions>
    </Card>
  )
}

Restaurant.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    mobileNumber: PropTypes.string,
    address: PropTypes.string,
    googleMaps: PropTypes.string,
    thumbnail: PropTypes.string,
    isDelivery: PropTypes.bool,
    isDailyMenu: PropTypes.bool,
    types: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
}

export default Restaurant

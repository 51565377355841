import {get} from 'lodash'


export const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const isRestaurantOpen = ({title, openingHours}) => {
  const todayDate = new Date()
  const todayIndex = todayDate.getDay()
  const {isSaturdayOpen, isSundayOpen} = openingHours

  if (todayIndex === 6 && !isSaturdayOpen) return false
  if (todayIndex === 0 && !isSundayOpen) return false

  const day = days[todayIndex]
  const hours = openingHours[day]
  const from = new Date(get(hours, 'from', ''))
  const to = new Date(get(hours, 'to', ''))
  const now = todayDate.getHours() * 60 + todayDate.getMinutes()
  const start = from.getHours() * 60 + from.getMinutes()
  const end = to.getHours() * 60 + to.getMinutes()


  return start <= now && now <= end
}

import React from 'react'
import PropTypes from 'prop-types'
import {map, orderBy} from 'lodash'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Restaurants from '../components/Restaurants/Restaurants'
import Filters from '../components/Filters'
import {isRestaurantOpen} from '../utils'


export const data = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            ...Restaurant
          }
        }
      }
    }
  }
`

const filteredRestaurants = (restaurants, conditions) => restaurants
  .filter((restaurant) => !conditions.isDailyMenu || restaurant.isDailyMenu)
  .filter((restaurant) => !conditions.isDelivery || restaurant.isDelivery)

const IndexPage = ({data: {allMarkdownRemark: {edges}}}) => {
  const [isMenuSelected, setMenuSelected] = React.useState(false)
  const [isDeliverySelected, setDeliverySelected] = React.useState(false)

  const conditions = {
    isDailyMenu: isMenuSelected,
    isDelivery: isDeliverySelected,
  }

  const restaurants = map(
    edges,
    ({node: {id, frontmatter}}) => ({id, isOpen: isRestaurantOpen(frontmatter), ...frontmatter})
  )

  return (
    <Layout>
      <Seo title="Domov" />
      <Filters
          isMenuSelected={isMenuSelected}
          setMenuSelected={setMenuSelected}
          isDeliverySelected={isDeliverySelected}
          setDeliverySelected={setDeliverySelected}
      />
      <Restaurants
          data={
            orderBy(filteredRestaurants(restaurants, conditions), ['isOpen', 'priority'], ['desc', 'asc'])
          }
      />
    </Layout>
  )
}


IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage
